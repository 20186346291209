import { Picture } from '@troon/ui';
import { mergeProps } from 'solid-js';
import { ImageOverlay } from '../image-overlay';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	src?: string | null;
	alt?: string;
	size?: 'default' | 'large';
}>;

export function Hero(inputProps: Props) {
	const props = mergeProps({ size: 'default' }, inputProps);
	return (
		<div class="relative mb-12 flex min-h-64 flex-col items-center bg-brand sm:min-h-72 md:min-h-96">
			<Picture
				src={props.src!}
				alt={props.alt ?? ''}
				sizes={
					props.size === 'default'
						? [
								[480, 256],
								[960, 384],
								[1600, 384],
							]
						: [
								[480, 640],
								[960, 640],
								[1600, 1024],
							]
				}
				class="absolute size-full bg-center object-cover brightness-75"
				loading="eager"
			/>
			<div class="z-10 flex grow items-center justify-center pt-16">{props.children}</div>
			<ImageOverlay class="relative top-1" />
		</div>
	);
}
